import React, {MouseEvent} from 'react';
import Arrow from "../../images/ui/long_arrow.png";
import Loading from "../general/loading"
import CustomTransitionLink from "../transition-wrapper/custom-transition-link"
import useMediaQuery from "../media-query/use-media-query"
import { Link } from "gatsby"

interface BaseButtonProps {
    inverse?: boolean
    basic_link?: boolean
    type?: "button" | "submit" | "reset"
    children?: React.ReactNode
    disabled?: boolean
    isLoading?: boolean
    icon?: any
    to?: string
    className?: string
    style?: React.CSSProperties
    onClick?: (e: (MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement> | null)) => void
    disableArrowAnimation?: boolean,
    ariaLabel?: string,
    id?: string,
    textClassName?: string
}

function BaseButton (
    {
        children,
        inverse=false,
        disabled,
        disableArrowAnimation,
        isLoading,
        icon,
        textClassName
    } : BaseButtonProps) {


    const color = inverse ? "text-primary-default" : "text-background-light"

    return (
        <>

            <span
                className={"flex items-center justify-center"}
                style={{ opacity:  (isLoading) ? "0.0" : "1.0", transform: `translateY(${(isLoading) ? "8px" : "0"})`, transition: "0.6s", transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)" }}
            >
                <span
                    className={`${textClassName ? textClassName : "text-base"} font-sans font-medium select-none ${color} ${ disabled ? "opacity-50" : "" } transform ${ disableArrowAnimation ? "" : `translate-x-2 group-hover:-translate-x-3` }`}
                    style={{ transition: "0.5s", transitionTimingFunction: "cubic-bezier(.11,.87,.43,.92)" }}
                >
                    {children}
                </span>
                { icon && <span style={{ marginRight: "12px", marginTop: "2px" }}> {icon}</span> }
                {!disableArrowAnimation &&
                    <span
                      className={`select-none ${color} ${ disabled ? "opacity-50" : "" } opacity-0 group-hover:opacity-100 transform translate-y- translate-x-4 group-hover:-translate-x-0`}
                      style={{ transition: "0.5s", transitionTimingFunction: "cubic-bezier(.11,.87,.43,.92)", width: "16px" }}
                    >
                        <img src={Arrow} width={16} alt={"Arrow"}/>
                    </span>
                }
            </span>

            <span
                style={{
                    position: "absolute", opacity: isLoading ? 1 : 0,
                    transform: `translateY(${(isLoading) ? "0px" : "-9px"})`,  transition: "0.4s", transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)"
                }}
            >
                <Loading/>
            </span>
        </>
    )
}

function FormButton (props: BaseButtonProps) {

    const background = props.inverse ? "bg-background-default" : "bg-primary-default"
    const loadingBackground = props.inverse ? "bg-white" : "bg-primary-lighter"
    const hoverBackground = props.inverse ? "focus:bg-background-dark" : "focus:bg-primary-lighter"
    const focusBackground = props.inverse ? "hover:bg-background-dark" : "hover:bg-primary-lighter"
    const activeBackground = props.inverse ? "active:bg-white" : "active:bg-primary-lighter"

    let className = `${props.className} group w-full relative inline-flex ${props.isLoading ? `${loadingBackground} pointer-events-none` : background} ${focusBackground} ${hoverBackground} ${activeBackground} transition duration-300 rounded-2xl justify-center items-center px-9 py-3.5`

    const smDown = useMediaQuery("(max-width: 450px)")
    const base = <BaseButton {...props} disableArrowAnimation={smDown ? true : props.disableArrowAnimation}/>;

    return ( props.to ?
            ((props.to.includes("http") || props.to.includes("mailto")) ?
                    <a id={props.id}
                       href={props.to}
                       target={"_blank"}
                       aria-label={props.ariaLabel}
                       style={{ ...props.style, pointerEvents: (props.disabled  || props.isLoading) ? "none" : "unset"}}
                       className={className}
                       onClick={() => { props.onClick?.(null) }}
                    >
                        {base}
                    </a>
                    :
                    (
                        props.basic_link ?
                            <Link
                                id={props.id}
                                ariaLabel={props.ariaLabel}
                                to={props.to}
                                style={{ ...props.style, pointerEvents: (props.disabled  || props.isLoading) ? "none" : "unset" }}
                                className={className}
                                onClick={() => { props.onClick?.(null) } }
                            >
                                {base}
                            </Link>
                            :
                            <CustomTransitionLink
                                id={props.id}
                                ariaLabel={props.ariaLabel}
                                to={props.to}
                                style={{ ...props.style, pointerEvents: (props.disabled  || props.isLoading) ? "none" : "unset" }}
                                className={className}
                                onClick={() => { props.onClick?.(null) } }
                            >
                                {base}
                            </CustomTransitionLink>
                    )
            )
            :
            <button
                id={props.id}
                aria-label={props.ariaLabel}
                type={props.type}
                style={{ ...props.style}}
                disabled={props.disabled  || props.isLoading}
                onClick={props.onClick===null ? () => {} : props.onClick}
                className={className}
            >
                {base}
            </button>
    )
}

export default FormButton;