import { User } from "firebase/auth";
import {UserData} from "../../models/user-data";

// Action Types
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_CHECKOUT_PAGE = 'UPDATE_CHECKOUT_PAGE';
export const UPDATE_TRANSITION_STATE = 'UPDATE_TRANSITION_STATE';

interface SetUser {
    type: typeof SET_USER,
    payload: {
        user: User | null,
        userData: UserData | null
    }
}

interface UpdateUser {
    type: typeof UPDATE_USER,
    payload: UserData
}


interface UpdateCheckoutPage {
    type: typeof UPDATE_CHECKOUT_PAGE,
    payload: {
        previousPath: string,
        toShow: boolean,
        transition_status: string
    }
}

interface UpdateTransitionState {
    type: typeof UPDATE_TRANSITION_STATE,
    payload: {
        transition_status: string
    }
}

export type UserActionTypes = SetUser | UpdateUser | UpdateCheckoutPage | UpdateTransitionState

// State
export interface UserState {
    loading?: boolean,
    userData: UserData | null,
    isAuthenticated?: boolean,
    isVerified?: boolean
    toShow?: boolean
    previousPath?: string
    transition_status?: string
}