import React from "react";
import './src/components/layout/layout.css';
import {createStore} from "redux";
import allReducers from "./src/redux/reducers";
import {Provider} from "react-redux";
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {

    const store = createStore(allReducers);

    return (
        <Provider store={store}>
            {element}
        </Provider>
    )
}

export const shouldUpdateScroll = ({prevRouterProps, pathname}) => {
    window.setTimeout(() => {
        if (!(((prevRouterProps && prevRouterProps.location.pathname.includes("/checkout")) || true) && pathname.includes("/checkout"))) {
            window.scrollTo( 0, 0 );
        }
    }, 300);
    return false;
};


