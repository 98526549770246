import {
    SET_USER,
    UPDATE_CHECKOUT_PAGE,
    UPDATE_TRANSITION_STATE,
    UPDATE_USER,
    UserActionTypes,
    UserState
} from "../../types/user-type"

const initialState: UserState = {
    loading: true,
    userData: null,
    isAuthenticated: false,
    isVerified: false,
    toShow: false
};

export function userReducer (state = initialState, action: UserActionTypes): UserState {
    switch (action.type) {
        case UPDATE_CHECKOUT_PAGE:
            return {
                ...state,
                toShow: action.payload.toShow,
                previousPath: action.payload.previousPath,
                transition_status: action.payload.transition_status
            }
        case UPDATE_TRANSITION_STATE:
            return {
                ...state,
                transition_status: action.payload.transition_status
            }
        case SET_USER:
            return {
                ...state,
                userData: action.payload.userData,
                isAuthenticated: action.payload.user!==null,
                isVerified: (action.payload.user!==null && action.payload.user.emailVerified),
                loading: false
            }
        case UPDATE_USER:
            return {
                ...state,
                userData: action.payload
            }

        default:
            return state
    }
}

export default userReducer;