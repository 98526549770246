import React, {Fragment} from "react";
import { Dialog, Transition } from '@headlessui/react';

const SlidingDialog = ({open=false, onClose, children, className = ""}: {className?:string, open: boolean, onClose: () => void, children: React.ReactNode}) => {

    return (
        (Transition && Dialog) ? (
            <Transition show={open} as={Fragment}>
                <Dialog as="div" className="z-30 fixed inset-0 overflow-hidden" onClose={onClose} >
                    <div className="absolute inset-0 overflow-hidden">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="absolute inset-0 transition-opacity" style={{ background: "rgba(70,60,50,0.45)" }}/>
                        </Transition.Child>
                        <div className="fixed inset-y-0 right-0 pl-6 sm:pl-10 max-w-full flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-[cubic-bezier(0.33, 0, 0, 1)] duration-300"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-[cubic-bezier(0.33, 0, 0, 1)] duration-300"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className={"relative w-screen max-w-lg"} >
                                    <div
                                        className={`h-full flex flex-col shadow-xl overflow-y-scroll ${className ? className : "bg-background-default"}`}
                                        style={{
                                            boxShadow: "0 4px 10px -1px rgb(190 193 214 / 25%)"
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        ) : <></>
    )

}

export default SlidingDialog;