import React from "react";
import "./container.css";

const CustomContainer = ({ children, style, className="" }: { children: React.ReactNode, style?: React.CSSProperties, className?: string}) => {

    return (
        <div className={`custom-container ${className}`} style={{...style}}>
            {children}
        </div>
    )
}

export default CustomContainer;