import React  from "react"
import CartWrapper from "./cart-drawer/cart-wrapper"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/reducers"
import AccountWrapper from "./my-account-dialog/account-wrapper"
import CustomContainer from "../general/contianer"
import "./navigation-bar.css"
import ChangingText from "./changing-text"
import { VscAccount } from "@react-icons/all-files/vsc/VscAccount"
import { FiShoppingCart } from "@react-icons/all-files/fi/FiShoppingCart"

const MiniTopBar = () => {

    const { totalItems } = useSelector((state: RootState) => state.cart);

    const menuClass = "flex h-full w-16 items-center justify-center relative transition duration-300 hover:opacity-80";

    return (
        <div className={"relative flex h-8 md:h-12"} style={{ background: "rgba(0,8,41, 0.98)" }}>
            <CustomContainer>
                <ChangingText/>
                <div style={{ flex: 1 }}/>
                <div className={"hidden md:flex h-full justify-end"}>
                    <AccountWrapper className={"h-full"}>
                        <span className={menuClass} style={{ borderLeft: "1px solid rgba(150,150,150,0.5)" }}>
                            <VscAccount size={20} color={'white'}/>
                        </span>
                    </AccountWrapper>
                    <CartWrapper>
                        <div role={"button"} className={menuClass} style={{ borderRight: "1px solid rgba(150,150,150,0.5)", borderLeft: "1px solid rgba(150,150,150,0.5)" }}>
                            <FiShoppingCart size={18} color={'white'} strokeWidth={1.5}/>
                            { totalItems !== 0 &&
                                <div className={"cart-counter"} style={{  background: "rgb(60,60,60)" }}>
                                  <p className={"font-sans font-semibold"} style={{ fontSize: "11px", color: "white", transform: "" }}>{totalItems}</p>
                                </div>
                            }
                        </div>
                    </CartWrapper>
                </div>
            </CustomContainer>
        </div>
    )

}

export default MiniTopBar;