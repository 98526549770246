import {
    ADD_DISCOUNT,
    ADD_ITEM,
    ADD_MEMBER_POINTS,
    CartActionTypes,
    CartState,
    DELETE_ITEM,
    REMOVE_DISCOUNT,
    REMOVE_MEMBER_POINTS,
    SET_CART,
    UPDATE_DELIVERY,
    UPDATE_PAYMENT,
    UPDATE_SHIPPING
} from "../../types/cart-type"
import { CartItem } from "../../../models/product-item"
import { doc, updateDoc } from "firebase/firestore/lite"
import { users } from "../../../utils/firebase"
import { imageKitLink, validateCoupon } from "../../../utils/fomatters"

const initialState: CartState = new CartState();


const updateCartData = (cart: CartItem[], uid: string | null, cart_state:CartState, added_item?: CartItem) => {
    if (uid) {
        let updatedCart: any= [];
        cart.forEach((c) => {
            let data = c.toFirebaseData();
            updatedCart.push(data);
        })
      updateDoc(doc(users, uid), { cart: updatedCart }).then();
    } else {
        let jsonString = "";
        cart.forEach((c) => {
            let data = c.toFirebaseData();
            jsonString += JSON.stringify(data) + " + ";
        })
        localStorage.setItem("cart", jsonString)
    }

    if (added_item) {
        let _learnq = window['_learnq'] || [];

        _learnq.push(["track", "Added to Cart", {
            "cart_state": JSON.stringify(cart_state),
            "$value": cart_state.orderTotal,
            "AddedItemProductName": added_item.variationTitle,
            "AddedItemProductID": added_item.id,
            "AddedItemSKU": added_item.id,
            "AddedItemCategory": added_item.categoryTitle,
            "AddedItemCategories": added_item.category,
            "AddedItemImageUrl": imageKitLink(added_item.thumbnail, { tr: 'f-webp,w-500' }),
            "AddedItemUrl": `https://groomd.co.in/products/${added_item.urlString}`,
            "AddedItemPrice": added_item.price,
            "AddedItemQuantity": added_item.quantity,
            "AddedItemVariation": added_item.variationId,
            "ItemNames": cart.map(i => i.variationTitle),
            "CheckoutURL": "https://groomd.co.in/checkout",
            "Items": cart.map(i => i.toKlaviyoItem())
        }]);
    }
}


const updateOtherData = (field:string, data:any, uid) => {
    if (uid) {
        updateDoc(doc(users, uid), { [`cart_${field}`]: data }).then();
    }
    localStorage.setItem(field, data)
}

export function cartReducer (state = initialState, action: CartActionTypes): CartState {

    switch (action.type) {

        case ADD_ITEM:
            const i: number = state.items.findIndex((c) => (c.id===action.payload.product.id && c.variationId === action.payload.variation));
            if (i===-1) {
                let newItem: CartItem = new CartItem(action.payload.product.toJson());
                newItem.variationId = action.payload.variation;
                newItem.quantity = 1;


                let promotionalDiscount = 0;
                if (state.discount && validateCoupon(newItem.id,[...newItem.category], state.discount)) {
                    promotionalDiscount += ((newItem.price) * state.discount.discount);
                }

                let new_state = {
                    ...state,
                    items: [...state.items, newItem],
                    totalItems: state.totalItems + 1,
                    orderValue: state.orderValue + newItem.originalPrice,
                    orderValueWithTaxes: state.orderValueWithTaxes + newItem.price,
                    retailDiscount: state.retailDiscount + newItem.discount,
                    promotionalDiscount: state.promotionalDiscount + promotionalDiscount,
                    totalDiscount: state.totalDiscount + newItem.discount + promotionalDiscount
                }

                updateCartData([...state.items, newItem], state.uid ?? null, new_state, newItem);

                return new CartState(new_state);
            } else {
                let newItem: CartItem = new CartItem(action.payload.product.toJson());
                newItem.variationId = action.payload.variation;
                newItem.quantity = 1;

                let currentItems: CartItem[] = state.items;
                currentItems[i].quantity += 1;


                let promotionalDiscount = 0;
                if (state.discount && validateCoupon(currentItems[i].id,[...currentItems[i].category], state.discount)) {
                    promotionalDiscount += ((currentItems[i].price) * state.discount.discount);
                }

                let new_state = {
                    ...state,
                    items: currentItems,
                    totalItems: state.totalItems + 1,
                    orderValue: state.orderValue + currentItems[i].originalPrice,
                    orderValueWithTaxes: state.orderValueWithTaxes + currentItems[i].price,
                    retailDiscount: state.retailDiscount + currentItems[i].discount,
                    promotionalDiscount: state.promotionalDiscount + promotionalDiscount,
                    totalDiscount: state.totalDiscount + currentItems[i].discount + promotionalDiscount
                }

                updateCartData(currentItems, state.uid ?? null, new_state, newItem);

                return new CartState(new_state);
            }

        case DELETE_ITEM:
            const j: number = state.items.findIndex((c) => (c.id===action.payload.product.id && c.variationId === action.payload.variation));
            if (j===-1) {
                return state;
            } else if (!action.payload.clear && state.items[j].quantity > 1) {
                let currentItems: CartItem[] = state.items;
                currentItems[j].quantity -= 1;


                let promotionalDiscount = 0;
                if (state.discount && validateCoupon(currentItems[j].id,[...currentItems[j].category], state.discount)) {
                    promotionalDiscount += ((currentItems[j].price) * state.discount.discount);
                }

                let new_state = {
                    ...state,
                    items: currentItems,
                    totalItems: state.totalItems - 1,
                    orderValue: state.orderValue - currentItems[j].originalPrice,
                    orderValueWithTaxes: state.orderValueWithTaxes - currentItems[j].price,
                    retailDiscount: state.retailDiscount - currentItems[j].discount,
                    promotionalDiscount: state.promotionalDiscount - promotionalDiscount,
                    totalDiscount: state.totalDiscount - currentItems[j].discount - promotionalDiscount
                };

                updateCartData(currentItems, state.uid ?? null, new_state);

                return new CartState(new_state);
            } else {
                let currentItems: CartItem[] = state.items;
                let orderValue = currentItems[j].originalPriceTotal;
                let price = currentItems[j].totalPrice;
                let discount = currentItems[j].totalDiscount;
                let quantity = currentItems[j].quantity;
                let promotionalDiscount = 0;
                if (state.discount && validateCoupon(currentItems[j].id,[...currentItems[j].category], state.discount)) {
                    promotionalDiscount += ((currentItems[j].totalPrice) * state.discount.discount);
                }
                currentItems.splice(j, 1);

                let new_state = {
                    ...state,
                    items: currentItems,
                    totalItems: state.totalItems - quantity,
                    orderValue: state.orderValue - orderValue,
                    orderValueWithTaxes: state.orderValueWithTaxes - price,
                    retailDiscount: state.retailDiscount - discount,
                    promotionalDiscount: state.promotionalDiscount - promotionalDiscount,
                    totalDiscount: state.totalDiscount - discount - promotionalDiscount
                };

                updateCartData(currentItems, state.uid ?? null, new_state);

                return new CartState(new_state);
            }

        case SET_CART:
            let totalQuantity = 0;
            let orderValue = 0;
            let totalPrice = 0;
            let totalDiscount = 0;
            let totalPromotionalDiscount = 0;
            let totalRetailDiscount = 0;

            action.payload.cart.forEach((c: CartItem) => {
                totalPrice += c.totalPrice;
                orderValue += c.originalPriceTotal;
                totalQuantity += c.quantity;
                totalRetailDiscount += c.totalDiscount;
                totalDiscount += c.totalDiscount;
                if (action.payload.discount && validateCoupon(c.id, [...c.category], action.payload.discount)) {
                    totalPromotionalDiscount += ((c.totalPrice) * action.payload.discount.discount);
                    totalDiscount += totalPromotionalDiscount;
                }
            })


            if (action.payload.memberPoints) {
                totalDiscount += action.payload.memberPoints
            }

            return new CartState(
                {
                    loading: false,
                    items: action.payload.cart,
                    uid: action.payload.uid,
                    discount: action.payload.discount,
                    memberDiscount: action.payload.memberPoints,
                    card: action.payload.card,
                    method: action.payload.method,
                    methodInstrument: action.payload.methodInstrument,
                    address: action.payload.address,
                    delivery_type: action.payload.delivery_type,
                    totalItems: totalQuantity,
                    orderValueWithTaxes: totalPrice,
                    orderValue: orderValue,
                    retailDiscount: totalRetailDiscount,
                    totalDiscount: totalDiscount,
                    promotionalDiscount: totalPromotionalDiscount
                }
            );

        case ADD_DISCOUNT:
            updateOtherData("discount_code", JSON.stringify(action.payload.discount.toJson()), state.uid);
            let promotionalDiscount = 0;
            let c_promotionDiscount = state.promotionalDiscount;

            state.items.forEach(c => {
                if (validateCoupon(c.id, [...c.category], action.payload.discount)) {
                    promotionalDiscount += ((c.totalPrice) * action.payload.discount.discount);
                }
            })

            return new CartState(
                {
                    ...state,
                    discount: action.payload.discount,
                    promotionalDiscount: promotionalDiscount,
                    totalDiscount: state.totalDiscount - c_promotionDiscount + promotionalDiscount,
                }
            );

        case REMOVE_DISCOUNT:
            updateOtherData("discount_code", "", state.uid);

            let promotionalDiscountX = 0;
            state.items.forEach(c => {
                if (validateCoupon(c.id, [...c.category], action.payload.discount)) {
                    promotionalDiscountX += ((c.totalPrice) * action.payload.discount.discount);
                }
            })

            return new CartState(
                {
                    ...state,
                    discount: null,
                    promotionalDiscount: 0,
                    totalDiscount: state.totalDiscount - state.promotionalDiscount
                }
            );

        case ADD_MEMBER_POINTS:
            updateOtherData("member_points", action.payload.memberPoints, state.uid);
            return new CartState(
                {
                    ...state,
                    memberDiscount: action.payload.memberPoints,
                    totalDiscount: state.totalDiscount + action.payload.memberPoints,
                }
            );

        case REMOVE_MEMBER_POINTS:
            updateOtherData("member_points", 0, state.uid);
            return new CartState(
                {
                    ...state,
                    memberDiscount: 0,
                    totalDiscount: state.totalDiscount - state.memberDiscount
                }
            );

        case UPDATE_PAYMENT:
            updateOtherData("payment_method_instrument", action.payload.methodInstrument, state.uid);
            updateOtherData("payment_method", action.payload.method, state.uid);
            updateOtherData("card", JSON.stringify(action.payload.card.toJson()), state.uid);
            return new CartState(
                {
                    ...state,
                    card: action.payload.card,
                    method: action.payload.method,
                    methodInstrument: action.payload.methodInstrument
                }
            );

        case UPDATE_SHIPPING:
            updateOtherData("shipping_address", JSON.stringify(action.payload.address.toJson()), state.uid);
            return new CartState(
                {
                    ...state,
                    address: action.payload.address
                }
            );

        case UPDATE_DELIVERY:
            updateOtherData("delivery_type", action.payload.delivery_type, state.uid);
            return new CartState(
                {
                    ...state,
                    delivery_type: action.payload.delivery_type
                }
            );
        default:
            return state
    }
}

export default cartReducer;