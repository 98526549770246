export default class Address {
    id: string = "";
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    addressLineOne: string = "";
    addressLineTwo: string = "";
    city: string = "";
    state: string = "";
    country: string = "";
    pinCode: string = "";
    phoneNumber: string = "";
    cod: boolean = false;
    prepaid: boolean = false;

    constructor(data?: {[field: string]: any}) {
        this.id = data?.id ?? "";
        this.firstName = data?.firstName ?? "";
        this.lastName = data?.lastName ?? "";
        this.email = data?.email ?? "";
        this.addressLineOne = data?.addressLineOne ?? "";
        this.addressLineTwo = data?.addressLineTwo ?? "";
        this.city = data?.city ?? "";
        this.state = data?.state ?? "";
        this.country = data?.country ?? "";
        this.pinCode = data?.pinCode ?? "";
        this.phoneNumber = data?.phoneNumber ?? "";
        this.cod = data?.cod ?? false;
        this.prepaid = data?.prepaid ?? false;
    }

    toJson(): Address {
        return Object.assign({}, this);
    }

    get primaryLine() {
        return this.addressLineOne + (this.addressLineTwo ? `, ${this.addressLineTwo}` : "");
    }

    get name() {
        return (this.firstName ?? "") + " " + (this.lastName ?? "");
    }

    get secondaryLine() {
        return this.city + ", " + this.state;
    }

    get secondaryLineWithZipCode() {
        return (this.city + ", " + this.state + ", " + this.pinCode ).trim();
    }

    get exists() {
        return (!!this.addressLineOne && !!this.firstName && !!this.lastName && !!this.city && !!this.state)
    }
}
