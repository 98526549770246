import Visa from "../images/card_logos/visa.jpg";
import MasterCard from "../images/card_logos/mastercard.jpg";
import Jcb from "../images/card_logos/jcb.jpg";
import Discover from "../images/card_logos/discover.jpg";
import Diners from "../images/card_logos/diners.jpg";
import Union from "../images/card_logos/unionpay.jpg";
import Amex from "../images/card_logos/amex.jpg";
import Generic from "../images/card_logos/generic.jpg";
import RuPay from "../images/card_logos/rupay.png";

export default class CreditCard {
    id: string = "";
    brand: string = "";
    last4: string = "";
    exp_month: number = 0;
    exp_year: number = 0;
    should_save: boolean = false;

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? "";
        this.brand = data?.brand ?? "";
        this.last4 = data?.last4 ?? "";
        this.exp_month = data?.exp_month ?? 0;
        this.exp_year = data?.exp_year ?? 0;
        this.should_save = data?.should_save ?? false;
    }

    toJson(): CreditCard {
        return Object.assign({}, this);
    }

    get brandIcon () {
        switch (this.brand) {
            case "American Express":
            case "amex":
                return Amex;
            case "Diners Club":
                return Diners;
            case "Discover":
            case "discover":
                return Discover;
            case "JCB":
                return Jcb;
            case "Visa":
            case 'visa':
                return Visa;
            case "MasterCard":
            case "mastercard":
                return MasterCard;
            case "UnionPay":
                return Union;
            default:
                return Generic;
        }
    }
}

export const getBrandIcon = (v) => {
    switch (v) {
        case "American Express":
        case "amex":
            return Amex;
        case "Diners Club":
            return Diners;
        case "Discover":
        case "discover":
            return Discover;
        case "JCB":
            return Jcb;
        case "Visa":
        case 'visa':
            return Visa;
        case "MasterCard":
        case "mastercard":
            return MasterCard;
        case "UnionPay":
            return Union;
        case "RuPay":
            return RuPay;
        default:
            return Generic;
    }
}
