import cartReducer from "./cart/cart-reducer";
import {combineReducers} from "redux";
import userReducer from "./user/user-reducer";

const allReducers = combineReducers({
    cart: cartReducer,
    user: userReducer
});

export type RootState = ReturnType<typeof allReducers>
export default allReducers;