import * as React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers";
import FormButton from "../../form-components/form-button";
import CustomTransitionLink from "../../transition-wrapper/custom-transition-link"
import { Popover, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"
import { auth } from "../../../utils/firebase"
import { signOut } from "firebase/auth";

function AccountWrapper({children, className=""} : {children: React.ReactNode, className?: string}) {

    const { isAuthenticated } = useSelector((state: RootState) => state.user);
    const { items } = useSelector((state: RootState) => state.cart);

    return (
        <>
            <Popover className="relative h-full">
                {({ close }) => (
                    <>
                    <Popover.Button id={"navigation-account"} className={className}>
                        {children}
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute z-30 w-screen mt-3 transform right-0 lg:max-w-xs" style={{ maxWidth: "320px" }}>
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid grid-cols-1 gap-4 bg-background-light p-6">
                                    {!isAuthenticated &&
                                        <>
                                            <FormButton onClick={() => { close() }} ariaLabel={"Sign In to your Groomd Account"} to={"/account/sign-in"} id={"my_account_sign_in"}>Sign In</FormButton>
                                            <FormButton onClick={() => { close() }} ariaLabel={"Sign up for a Groomd Account"} type={"button"} to={"/account/become-a-member"} inverse className={"border border-gray-400"} disableArrowAnimation>Become a Member</FormButton>
                                            <div className={"h-1 w-full bg-background-dark my-1"}/>
                                        </>
                                    }
                                    {isAuthenticated &&
                                        <>
                                          <CustomTransitionLink
                                            to={"/account"}
                                            onClick={() => { close() }}
                                            className="flex items-center py-2 px-0 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-background-default focus:outline-none focus-visible:ring focus-visible:ring-primary-default focus-visible:ring-opacity-50"
                                          >
                                            <div className="ml-4">
                                              <p className="text-sm font-sans font-medium text-gray-900">
                                                Your Account
                                              </p>
                                            </div>
                                          </CustomTransitionLink>
                                          <div role={"button"}
                                               onClick={() => {
                                                   let jsonString = "";
                                                   items.forEach((c) => {
                                                       let data = c.toFirebaseData();
                                                       jsonString += JSON.stringify(data) + " + ";
                                                   })
                                                   localStorage.setItem("cart", jsonString);
                                                   signOut(auth).then(() => {});
                                                   close()
                                               }}
                                               className="flex items-center py-2 px-0 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-background-default focus:outline-none focus-visible:ring focus-visible:ring-primary-default focus-visible:ring-opacity-50"
                                          >
                                            <div className="ml-4">
                                              <p className="text-sm font-sans font-medium text-gray-900">
                                                Sign out
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                    }
                                    <CustomTransitionLink
                                        to={"/track"}
                                        onClick={() => { close() }}
                                        className="flex items-center py-2 px-0 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-background-default focus:outline-none focus-visible:ring focus-visible:ring-primary-default focus-visible:ring-opacity-50"
                                    >
                                        <div className="ml-4">
                                            <p className="text-sm font-sans font-medium text-gray-900">
                                                Track Order
                                            </p>
                                            <p className="text-sm font-sans text-gray-500">
                                                Check the status of your order.
                                            </p>
                                        </div>
                                    </CustomTransitionLink>
                                    {/*))}*/}
                                    {/*TODO adjust menu buttons withnew popover*/}
                                    {/*<div style={{ padding: "12px", minWidth: "250px" }}>*/}
                                    {/*    {!isAuthenticated &&*/}
                                    {/*    <>*/}
                                    {/*      <FormButton to={"/account/sign-in"}>Sign In</FormButton>*/}
                                    {/*      <div style={{ margin: "16px 0", height: "1px", width: "100%", background: "rgb(200,200,200)" }}/>*/}
                                    {/*    </>*/}
                                    {/*    }*/}
                                    {/*    <CustomTransitionLink to={"/account"}>*/}
                                    {/*        <p className={"text-sm font-sans font-medium"}>*/}
                                    {/*            Your Account*/}
                                    {/*        </p>*/}
                                    {/*    </CustomTransitionLink>*/}
                                    {/*    <div style={{ height: "16px" }}/>*/}
                                    {/*    {!isAuthenticated ?*/}
                                    {/*        <CustomTransitionLink to={"/account/become-a-member"}>*/}
                                    {/*            <p className={"text-sm font-sans font-medium"}>*/}
                                    {/*                Become a Member*/}
                                    {/*            </p>*/}
                                    {/*        </CustomTransitionLink>*/}
                                    {/*        :*/}
                                    {/*        <div role={"button"} onClick={() => {*/}
                                    {/*            let jsonString = "";*/}
                                    {/*            items.forEach((c) => {*/}
                                    {/*                let data = c.toFirebaseData();*/}
                                    {/*                jsonString += JSON.stringify(data) + " + ";*/}
                                    {/*            })*/}
                                    {/*            localStorage.setItem("cart", jsonString);*/}
                                    {/*            signOut(auth).then(() => {});*/}
                                    {/*        }}>*/}
                                    {/*            <p className={"text-sm font-sans font-medium"}>*/}
                                    {/*                Sign Out*/}
                                    {/*            </p>*/}
                                    {/*        </div>*/}
                                    {/*    }*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
            </Popover>
        </>
    )
}

export default AccountWrapper;