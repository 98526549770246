export default class Discount {
    id: string = "";
    code: string = "";
    description: string = "";
    discount: number = 0;
    isAdmin: boolean = false;
    unique_user: boolean = false;
    restriction_type: "collections" | "products" | "none"
    includeCollections: string[] = [];
    includeProducts: string[] = [];
    excludeCollections: string[] = [];
    error: string = "";
    uid: string[] = [];

    constructor(data?: {[field: string]: any}) {
        this.id = data?.id ?? "";
        this.code = data?.code ?? "";
        this.description = data?.description ?? "";
        this.discount = data?.discount ?? 0;
        this.unique_user = data?.unique_user ?? false;
        this.error = data?.error ?? "";
        this.restriction_type = data?.restriction_type ?? "none";
        this.isAdmin = data?.isAdmin ?? false;
        this.excludeCollections = data?.excludeCollections ?? [];
        this.includeCollections = data?.includeCollections ?? [];
        this.includeProducts = data?.includeProducts ?? [];
        this.uid = data?.uid ?? [];
    }

    toJson(): Discount {
        return Object.assign({}, this);
    }
}
