import React, { EventHandler, HTMLAttributeAnchorTarget } from "react"
import TransitionLink from 'gatsby-plugin-transition-link';

const CustomTransitionLink = ({ id, children, to, className, onClick, style, ariaLabel, target}: { target?: HTMLAttributeAnchorTarget, ariaLabel?: string, id?:string, children: React.ReactNode, to?: string, className?: string, onClick?: EventHandler<any>, style?: React.CSSProperties}) => {

    return (
        <TransitionLink
            id={id}
            target={target}
            aria-label={ariaLabel}
            exit={{
                length: 0.3,
            }}
            entry={{
                length: 1,
                delay: 0.35,
            }}
            to={to}
            className={className}
            style={style}
            onClick={onClick}
        >
            {children}
        </TransitionLink>
    )

}

export default CustomTransitionLink;