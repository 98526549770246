// noinspection TypeScriptUnresolvedVariable

import React, { useEffect, useState } from "react"
import NavigationBar from "../navigation-bar/navigation-bar"
import ReactPixel from "react-facebook-pixel"
import loadable from "@loadable/component"
import { Script, ScriptStrategy } from "gatsby"

const BGTasks = loadable(() => import('./bg-tasks'));

const Layout = (props) => {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            const options = {
                autoConfig: true,
                debug: false
            };
            ReactPixel.init('784019535660376', undefined, options);
            setLoaded(true);
        }, 2000)
    }, []);

    // noinspection JSVoidFunctionReturnValueUsed
    return (
        <>
            <BGTasks/>
            <NavigationBar/>
            {props.children}
            {loaded && (
                <>
                    <Script src={"https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=YngxT3"} id={"klaviyo"} strategy={ScriptStrategy.idle}/>
                    <script key={"clarity"} id={"clarity"} type={"text/javascript"} >
                        {
                            // noinspection
                            (function(c,l,a,r,i,t,y){
                                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                            })(window, document, "clarity", "script", "dqpgyuxma0")
                        }
                    </script>
                </>
            )}
        </>
    )

}

export default Layout;