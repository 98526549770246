import app from "gatsby-plugin-firebase-v9.0"
import { getFirestore, collection } from "firebase/firestore/lite";
import { initializeAuth, indexedDBLocalPersistence, browserLocalPersistence } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Firebase Instances
export const auth = initializeAuth(app, {
    persistence: [indexedDBLocalPersistence, browserLocalPersistence]
});
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);

// Collections
export const users = collection(firestore, "users");
export const carts = collection(firestore, "carts");
export const user_exists = collection(firestore, "user_exists");
export const email_subscriptions = collection(firestore, "email_subscriptions");
export const products = collection(firestore, "products");
export const orders = collection(firestore, "orders");
export const promo_codes = collection(firestore, "promo_codes");
