import Vegan from "../images/promises/vegan.svg"
import CrueltyFree from "../images/promises/cruelty_free.svg"
import ChemicalsFree from "../images/promises/checimals.svg"
import SmallBatches from "../images/promises/small_batches.svg"
import Dermatologist from "../images/promises/derma.svg"
import Soothing from "../images/promises/soothing.svg"
import Balanced from "../images/promises/balanced.svg"
import SmellsGreat from "../images/promises/smells_great.svg"
import Gentle from "../images/promises/gentle.svg"
import Energizing from "../images/promises/energizing.svg"

export const BASE_API = "https://groomd.co.in/api"
// export const BASE_API = "http://127.0.0.1:5001/groomd-mens/asia-south1/delhivery/api"
export const IMAGEKIT_BASE = "/groomd"
export const PAYMENT_API = "https://us-central1-groomd-mens.cloudfunctions.net/checkout"


export const promises = [
    {
        id: "vegan",
        image: Vegan,
        alt: "100% Vegan",
        caption: "100% Vegan"
    },
    {
        id: "cruelty-free",
        image: CrueltyFree,
        alt: "Cruelty Free",
        caption: "Cruelty Free"
    },
    {
        id: "no-chemicals",
        image: ChemicalsFree,
        alt: "No Parabens/Sulphates",
        caption: "No Parabens/Sulphates"
    },
    {
        id: "small-batches",
        image: SmallBatches,
        alt: "Crafted in small batches",
        caption: "Crafted in small batches"
    },
    {
        id: "dermatologist-approved",
        image: Dermatologist,
        alt: "Dermatologist Approved",
        caption: "Dermatologist Approved"
    },
    {
        id: "soothing",
        image: Soothing,
        alt: "Soothing",
        caption: "Soothing"
    },
    {
        id: "balanced",
        image: Balanced,
        alt: "Balanced",
        caption: "Balanced"
    },
    {
        id: "smells-great",
        image: SmellsGreat,
        alt: "Smells Great",
        caption: "Smells Great"
    },
    {
        id: "gentle",
        image: Gentle,
        alt: "Gentle",
        caption: "Gentle"
    },
    {
        id: "energizing",
        image: Energizing,
        alt: "Energizing",
        caption: "Energizing"
    }
]