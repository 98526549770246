import Discount from "../models/discount"
import { IMAGEKIT_BASE } from "./constants"
import { useSelector } from "react-redux"
import { RootState } from "../redux/reducers"
import { CartItem } from "../models/product-item"
import md5 from "md5"

export const createCurrencyText = (value:number, {disableSymbol=false, currency="INR", minDecimal=0, maxDecimal=2}: {disableSymbol?:boolean, currency?: string, minDecimal?:number, maxDecimal?:number }) => {
    const hasDecimal = value.toString().includes(".");
    if (hasDecimal) {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: maxDecimal, style: disableSymbol ? "decimal" : 'currency', currency: currency}).format(value)
    } else {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: minDecimal, maximumFractionDigits: maxDecimal, style: disableSymbol ? "decimal" : 'currency', currency: currency}).format(value)
    }
}

export const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-IN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }).format(date)
}

export const imageKitLink = (url:string, options?:{tr:string}) => {
    return `https://ik.imagekit.io${IMAGEKIT_BASE}${url.replaceAll(" ", "%20")}?tr=${options?.tr ?? "f-webp"}`;
}

export const validateCoupon = (id: string, category: string[], discount: Discount):boolean => {

    let shouldInclude = false;
    switch (discount.restriction_type) {
        case "collections":
            discount.includeCollections.forEach(el => {
                if (category.includes(el))
                    shouldInclude = true;
            });
            break;
        case "products":
            if (discount.includeProducts.includes(id))
                shouldInclude = true;
            break;
        case "none":
            shouldInclude = true;
            break;
    }

    return shouldInclude;

}

export const validatePromoCode = (items:CartItem[], discount: Discount, email: string):Promise<boolean> => {

    return new Promise((resolve, reject) => {

        let shouldInclude = false;
        items.forEach(item => {
            switch (discount.restriction_type) {
                case "collections":
                    discount.includeCollections.forEach(el => {
                        if (item.category.includes(el))
                            shouldInclude = true;
                    });
                    break;
                case "products":
                    if (discount.includeProducts.includes(item.id))
                        shouldInclude = true;
                    break;
                case "none":
                    shouldInclude = true;
                    break;
            }
        })

        if (!shouldInclude) {
            return reject(discount.error ?? 'Sorry! Your cart has no applicable products for this promo code.')
        }

        if (discount.unique_user && discount.uid.findIndex((s) => (s === md5(email, {asString: true}).toString() ?? "")) !== -1) {
            return reject({coupon: "empty", text: "Promo Code can only used once."});
        }

        if (discount.isAdmin) {
            return reject({coupon: "empty", text: "Promo Code is invalid."});
        }

        return resolve(shouldInclude);
    })

}