import { IMAGEKIT_BASE } from "../utils/constants"
import { imageKitLink } from "../utils/fomatters"

export class SEO {
    title: string = "";
    description: string = "";
    image: string = "";
    keywords: string[] = [];

    constructor(data?: { [field: string]: any }) {
        this.title = data?.title ?? undefined;
        this.description = data?.description ?? "";
        this.image = data?.image ?? 0;
        this.keywords = data?.keywords ?? [];
    }

    toJson(): SEO {
        return Object.assign({}, this);
    }
}

export class Position {
    xsDown: string = "";
    smUp: string = "";

    constructor(data?: { [field: string]: any }) {
        this.xsDown = data?.xsDown ?? "";
        this.smUp = data?.smUp ?? "";
    }

    toJson(): Position {
        return Object.assign({}, this);
    }
}

export default class PageLink {
    icon: string = "";
    cover_image: string = "";
    title: string = "";
    subtitle: string = "";
    slug: string = "";
    type: string = "";
    to: string = "";
    collection_filter: string = "";
    color: string = "black";
    s_n: string = "";
    seo: SEO = new SEO();
    position: Position = new Position();

    constructor(data?: {[field: string]: any}) {
        this.icon = data?.icon ?? "";
        this.cover_image = data?.cover_image ?? "";
        this.title = data?.title ?? "";
        this.subtitle = data?.subtitle ?? "";
        this.slug = data?.slug ?? "";
        this.type = data?.type ?? "";
        this.to = data?.to ?? "";
        this.collection_filter = data?.collection_filter ?? "";
        this.color = data?.color ?? "black";
        this.s_n = data?.s_n ?? "";
        this.seo = new SEO(data.seo);
        this.position = new Position(data.position);
    }

    get imageKitLink () {
        return imageKitLink(this.cover_image, { tr: `f-webp,h-500` });
    }

    toJson(): PageLink {
        return Object.assign({}, this);
    }
}
