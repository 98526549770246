exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-corporate-gifting-index-tsx": () => import("./../../../src/pages/corporate-gifting/index.tsx" /* webpackChunkName: "component---src-pages-corporate-gifting-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-groomd-rewards-index-tsx": () => import("./../../../src/pages/groomd-rewards/index.tsx" /* webpackChunkName: "component---src-pages-groomd-rewards-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-ingredients-index-tsx": () => import("./../../../src/pages/our-ingredients/index.tsx" /* webpackChunkName: "component---src-pages-our-ingredients-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-shipping-and-returns-index-tsx": () => import("./../../../src/pages/shipping-and-returns/index.tsx" /* webpackChunkName: "component---src-pages-shipping-and-returns-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-thanks-index-tsx": () => import("./../../../src/pages/thanks/index.tsx" /* webpackChunkName: "component---src-pages-thanks-index-tsx" */),
  "component---src-pages-track-index-tsx": () => import("./../../../src/pages/track/index.tsx" /* webpackChunkName: "component---src-pages-track-index-tsx" */),
  "component---src-templates-collections-collections-tsx": () => import("./../../../src/templates/collections/collections.tsx" /* webpackChunkName: "component---src-templates-collections-collections-tsx" */),
  "component---src-templates-product-details-product-details-tsx": () => import("./../../../src/templates/product-details/product-details.tsx" /* webpackChunkName: "component---src-templates-product-details-product-details-tsx" */)
}

