import {ViewContent} from "react-facebook-pixel";
import { SEO } from "./page-link"
import { Item } from "firebase/analytics"
import { createCurrencyText, imageKitLink } from "../utils/fomatters"
import { IMAGEKIT_BASE } from "../utils/constants"

export class Variation {
    tag: string = "";
    title: string = "";
    price: number = 0;
    mrp: number = 0;

    constructor(data?: { [field: string]: any }) {
        this.tag = data?.tag ?? "";
        this.title = data?.title ?? "";
        this.price = data?.price ?? 0;
        this.mrp = data?.mrp ?? 0;
    }

    toJson(): Variation {
        return Object.assign({}, this);
    }
}

export class SubItems {
    id: string = "";
    urlString: string = "";
    title: string = "";
    ingredients: string = "";
    size: string = "";
    thumbnail: string = "";

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? "";
        this.title = data?.title ?? "";
        this.urlString = data?.urlString ?? "";
        this.ingredients = data?.ingredients ?? "";
        this.size = data?.size ?? "";
        this.thumbnail = data?.thumbnail ?? "";
    }

    get thumbnail_src (): string {
        return imageKitLink(this.thumbnail, { tr: `f-webp,w-80` })
    }

    toJson(): SubItems {
        return Object.assign({}, this);
    }
}

export class Ingredients {
    title: string = "";
    description: string = "";
    image: string = "";
    short_description: string = "";
    products: string[]

    constructor(data?: { [field: string]: any }) {
        this.title = data?.title ?? "";
        this.description = data?.description ?? "";
        this.image = data?.image ?? "";
        this.short_description = data?.short_description ?? "";
        this.products = data?.products ?? [];
    }

    toJson(): Ingredients {
        return Object.assign({}, this);
    }
}

export class CatalogueImages {
    defaultImage: string = "";
    onHoverImage: string = "";

    constructor(data?: { [field: string]: any }) {
        this.defaultImage = data?.defaultImage ?? "";
        this.onHoverImage = data?.onHoverImage ?? "";
    }

    imageKitLink (type: "default" | "hover") {
        let imgUrl = type === "default" ? this.defaultImage : this.onHoverImage;

        return imgUrl ? imageKitLink(imgUrl, { tr: 'f-webp,w-600' }) : "";
    }

    toJson(): CatalogueImages {
        return Object.assign({}, this);
    }
}

export class ProductItem {
    id: string = "";
    urlString: string = "";
    averageReview: number = 5;
    totalReviews: number = 0;
    recommendedPercentage: number = 0;
    subTitle: string = "";
    variation: Variation[] = [];
    subItems: SubItems[] = [];
    size: string = "";
    title: string = "";
    tagline: string = "";
    product_images: string[] = [];
    catalogue_images: CatalogueImages = new CatalogueImages();
    thumbnail: string = "";
    out_of_stock: boolean = false;
    disabled: boolean = false;
    good_to_know: string[] = [];
    description: string = "";
    directionSteps: string[] = [];
    directionTips: string[] = [];
    benefits: string[] = [];
    ingredients: Ingredients[] = [];
    featured_ingredients: Ingredients[] = [];
    category: string[] = [];
    categoryTitle: string = "";
    tags: string[] = [];
    featured_ingredients_title: string = "";
    taxable: boolean = true;
    seo: SEO = new SEO();

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? "";
        this.urlString = data?.urlString ?? "";
        this.averageReview = data?.averageReview ?? 5;
        this.totalReviews = data?.totalReviews ?? 0;
        this.recommendedPercentage = data?.recommendedPercentage ?? 0;
        this.subTitle = data?.subTitle ?? "";
        this.tagline = data?.tagline ?? "";
        if (data?.subItems) {
            this.subItems = [];
            data.subItems.forEach((s: any) => {
                this.subItems.push(new SubItems(s));
            });
        } else {
            this.subItems = [];
        }
        if (data?.variation) {
            this.variation = [];
            data.variation.forEach((v: any) => {
                this.variation.push(new Variation(v));
            });
        } else {
            this.variation = [];
        }
        this.title = data?.title ?? "";
        this.size = data?.size ?? "";
        this.product_images = data?.product_images!=null ? data.product_images : [];
        this.catalogue_images = new CatalogueImages(data.catalogue_images);
        this.thumbnail = data?.thumbnail ?? "";
        this.out_of_stock = data?.out_of_stock ?? false;
        this.disabled = data?.disabled ?? false;
        this.good_to_know = data?.good_to_know ?? [];
        this.description = data?.description ?? "";
        this.directionSteps = data?.directionSteps!=null ? data.directionSteps : [];
        this.directionTips = data?.directionTips!=null ? data.directionTips : [];
        this.benefits = data?.benefits!=null ? data.benefits : [];
        if (data?.ingredients) {
            this.ingredients = [];
            data.ingredients.forEach((ingredient: any) => {
                this.ingredients.push(new Ingredients(ingredient));
            });
        } else {
            this.ingredients = [];
        }
        if (data?.featured_ingredients) {
            this.featured_ingredients = [];
            data.featured_ingredients.forEach((featured_ingredient: any) => {
                this.featured_ingredients.push(new Ingredients(featured_ingredient));
            });
        } else {
            this.featured_ingredients = [];
        }
        this.tags = data?.tags!=null ? data.tags : [];
        this.featured_ingredients_title = data?.featured_ingredients_title ?? "";
        this.category = data?.category!=null ? data.category : [];
        this.categoryTitle = data?.categoryTitle ?? "";
        this.taxable = data?.taxable ?? true;

        this.seo = new SEO(data?.seo);
    }

    images (imgUrl) {
        return imgUrl ? imageKitLink(imgUrl, { tr: 'f-webp,w-1350' }) : "";
    }

    toJson() {
        let v = this.variation.map((x) => { return Object.assign({}, x) });
        let i = this.ingredients.map((x) => { return Object.assign({}, x) });
        let s = this.subItems.map((x) => { return Object.assign({}, x) });

        let data = Object.assign({}, this);
        data.variation = v;
        data.ingredients = i;
        data.subItems = s;
        return data;
    }

    toDataLayer(): Item {
        return {
            'item_name': this.title,
            'item_id': this.id,
            'price': this.lowestVariation.price,
            'item_brand': 'Groomd',
            'item_category': this.categoryTitle,
            'quantity': 1
        }
    }

    toKlaviyoItem(): Record<string, any> {
        let imgUrl = imageKitLink(this.thumbnail, { tr: 'f-webp,w-500' });

        return {
            ProductName: this.title,
            ProductID: this.id,
            SKU: this.id,
            Category: this.categoryTitle,
            Categories: this.category,
            ImageUrl: imgUrl,
            Url: `https://groomd.co.in/products/${this.urlString}`,
            Brand: "Groomd",
            Price: this.lowestVariation.price,
            FormattedPrice: createCurrencyText(this.lowestVariation.price, {}),
            CompareAtPrice: this.lowestVariation.mrp
        }
    }

    toKlaviyoRecentItem(): Record<string, any> {
        let imgUrl = imageKitLink(this.thumbnail, { tr: 'f-webp,w-500' });

        return {
            Title: this.title,
            ItemId: this.id,
            SKU: this.id,
            Category: this.categoryTitle,
            Categories: this.category,
            ImageUrl: imgUrl,
            Url: `https://groomd.co.in/products/${this.urlString}`,
            Metadata: {
                Brand: "Groomd",
                Price: this.lowestVariation.price,
                CompareAtPrice: this.lowestVariation.mrp
            }
        }
    }

    toFacebookEvent(): ViewContent {
        return {
            'content_type': 'product',
            'value': this.lowestVariation.price,
            'currency': 'inr',
            'content_name': this.title,
            'content_ids': [this.id]
        }
    }

    get lowestVariation (): Variation {
        let lowest = this.variation[0];
        this.variation.forEach(v => {
            if (lowest.price > v.price)
                lowest = v;
        })
        return lowest;
    }

    get thumbnail_src (): string {
        return imageKitLink(this.thumbnail, { tr: `f-webp,w-80` });
    }

    get subTitleString (): string {
        let s = "";
        if (this.ingredients.length > 0) {
            this.ingredients.forEach((ing, index) => {
                s += ing.title;
                if (index === this.ingredients.length - 2) {
                    s+= " & ";
                } else if (index !== this.ingredients.length - 1) {
                    s+=", ";
                }
            })
        } else {
            s = this.subTitle;
        }
        return s;
    }
}

export class CartItem extends ProductItem {
    quantity: number = 0;
    variationId: string = "";

    constructor(data?: { [field: string]: any }){
        super(data);
        this.quantity = data?.quantity ?? 0;
        this.variationId = data?.variationId ?? "";
    }

    toDataLayer(): Item {
        return {
            'item_name': this.title,
            'item_id': this.urlString,
            'price': this.price,
            'item_brand': 'Groomd',
            'item_category': this.categoryTitle,
            "item_variant": this.variationId,
            'quantity': this.quantity
        }
    }

    toKlaviyoItem(): Record<string, any> {
        return {
            ProductName: this.variationTitle,
            ProductID: this.id,
            SKU: this.id,
            Category: this.categoryTitle,
            Categories: this.category,
            ImageUrl: imageKitLink(this.thumbnail, { tr: `f-webp,w-500` }),
            Url: `https://groomd.co.in/products/${this.urlString}`,
            Brand: "Groomd",
            Price: this.price,
            FormattedPrice: createCurrencyText(this.price, {}),
            Variation: this.variationId,
            TotalPrice: this.totalPrice,
            FormattedTotalPrice: createCurrencyText(this.totalPrice, {}),
            Quantity: this.quantity,
            CompareAtPrice: this.lowestVariation.mrp,
            TotalCompareAtPrice: this.originalPriceTotal
        }
    }

    toEAB(): { id: string, quantity: number } {
        return {
            'id': this.id,
            'quantity': this.quantity
        }
    }

    toFirebaseData(): Pick<CartItem, 'id' | 'quantity' | 'variationId'> {
        return {
            id: this.id,
            quantity: this.quantity,
            variationId: this.variationId
        };
    }

    get variationTitle(): string {
        return `${this.title} ${this.variation.length > 1 ? `(${this.variation.find(v => v.tag === this.variationId)?.title})` : ""}`
    }

    get selectionVariation(): string {
        return `${this.variation.length > 1 ? `${this.variation.find(v => v.tag === this.variationId)?.title}` : ""}`
    }

    get price(): number {
        return (this.variation.find((item) => item.tag === this.variationId)?.price ?? 0)
    }

    get totalPrice(): number {
        return (this.variation.find((item) => item.tag === this.variationId)?.price ?? 0) * this.quantity
    }

    get totalDiscount(): number {
        if (this.originalPriceTotal === 0)
            return 0;
        if (this.originalPriceTotal > this.totalPrice)
            return (this.originalPriceTotal - this.totalPrice)
        else
            return 0
    }

    get discount(): number {
        let mrp = (this.variation.find((item) => item.tag === this.variationId)?.mrp ?? 0);
        if (mrp === 0)
            return 0;
        if (mrp > this.price)
            return mrp - this.price
        else
            return 0
    }

    get originalPrice(): number {
        return (this.variation.find((item) => item.tag === this.variationId)?.mrp ?? 0);

    }

    get originalPriceTotal(): number {
        return this.originalPrice * this.quantity;

    }

    get originalPriceWithoutTax(): number {
        return this.originalPrice / 1.18;

    }

    get originalPriceTotalWithoutTax(): number {
        return this.originalPriceTotal / 1.18;

    }
}